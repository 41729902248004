import { createContext } from "react";

export interface LoadingContextInterface<id> {
  loading: (what: string) => id;
  done: (ref: id) => void;
}

export const LoadingContext = createContext<LoadingContextInterface<number>>({
  loading: (what: string) => 0,
  done: () => {},
});

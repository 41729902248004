import logo from "../logo.svg";
export default function SeekApproval() {
  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <p>Votre compte n'est pas encore approuvé.</p>
      <p>
        Demandez à un administrateur d’approuver votre nouveau compte. Je (
        <a href="mailto:mathieulj@gmail.com">Mathieu Letendre-Jauniaux</a>) vais être sur place et sa me ferrai plaisir
        d’approuver votre compte instantanément. Je vais aussi rendre administrateur plusieurs personnes au fil de
        l’événement donc sa peut être un bon sujet brise glace!
      </p>
    </div>
  );
}

import useUsers, { UseUsers } from "../hooks/useUsers";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import { User } from "../types";
import BlockIcon from "@material-ui/icons/Block";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";

interface ManageUsersProps {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  cards: {
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "Repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "Repeat(3, 1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns: "Repeat(4, 1fr)",
    },
    gridGap: theme.spacing(2, 2),
    margin: theme.spacing(1),
  },
  close: {
    justifySelf: "end",
  },
  root: {
    margin: theme.spacing(1),
    display: "grid",
  },
}));

export default function ManageUsers({ onClose }: ManageUsersProps) {
  const { user: self } = useAuth0();
  const { users, ...rest } = useUsers();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton className={classes.close} onClick={onClose}>
        <CloseSharp />
      </IconButton>
      <div className={classes.cards}>
        {users.map((user) => (
          <UserCard user={user} key={user.id} isSelf={user.id === self?.sub} {...rest} />
        ))}
      </div>
    </div>
  );
}

interface UserCardProps extends Omit<UseUsers, "users"> {
  user: User;
  isSelf: boolean;
}

function UserCard(props: UserCardProps) {
  let { user, isSelf } = props;

  if (isSelf) {
    return (
      <Card>
        <CardHeader avatar={<Avatar src={user.picture} alt={user.name} />} title={user.email} subheader={user.name} />
      </Card>
    );
  }

  let ApprovedIcon = user.approved ? BlockIcon : VerifiedUserIcon;
  let approvedActionText = user.approved ? "Désactiver" : "Activer";
  let approvedAction = user.approved ? props.deactivate : props.activate;

  let AdminIcon = user.admin ? SupervisedUserCircleIcon : SupervisorAccountIcon;
  let adminActionText = user.admin ? "Rendre standard" : "Rendre administrateur";
  let adminAction = user.admin ? props.demote : props.promote;

  return (
    <Card>
      <CardHeader avatar={<Avatar src={user.picture} alt={user.name} />} title={user.email} subheader={user.name} />
      <CardActions>
        <Button aria-label="activate user" onClick={() => approvedAction(user)} startIcon={<ApprovedIcon />}>
          {approvedActionText}
        </Button>
        <Button aria-label="make user an administrator" onClick={() => adminAction(user)} startIcon={<AdminIcon />}>
          {adminActionText}
        </Button>
      </CardActions>
    </Card>
  );
}

import React, { ChangeEvent, useCallback, useState } from "react";
import "./App.css";
import NavBar from "./components/Navbar";
import { useAuth0, User } from "@auth0/auth0-react";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { AddAPhoto, CloudDownload, Delete, ZoomIn } from "@material-ui/icons";
import SeekApproval from "./components/SeekApproval";
import PleaseLogIn from "./components/PleaseLogIn";
import usePictures from "./hooks/usePictures";
import { Photo } from "./types";
import ManageUsers from "./components/ManageUsers";
import { LoadingContext } from "./LoadingContext";
import CircularProgress from "@material-ui/core/CircularProgress";

function getRoles(user: User): Array<string> {
  const ROLES = "https://mm2021.photo/roles";
  return user[ROLES] || [];
}

const useStyles = makeStyles((theme) => ({
  icon: {},
  actionBar: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: theme.spacing(-0.5),
    padding: theme.spacing(0, 0.5),
  },
  image: {
    borderRadius: theme.spacing(0.5),
    maxWidth: "100%",
    gridArea: "photo",
  },
  download: { gridArea: "download" },
  delete: { gridArea: "delete" },
  zoom: { gridArea: "zoom" },
  imageGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4) + 34,
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: theme.spacing(2, 2),
    gridGap: theme.spacing(2, 2),
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "start",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "Repeat(2, auto)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "Repeat(3, auto)",
    },
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns: "Repeat(4, auto)",
    },
  },
  imageTile: {
    display: "grid",
    gridTemplateAreas: `"photo photo photo" "delete zoom download"`,
    justifyItems: "center",
  },
  imageButton: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabLabel: {
    lineHeight: 1,
    "&>p": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  zoomed: {
    maxHeight: `calc(100% - ${theme.spacing(1)}px)`,
    maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
    borderRadius: theme.spacing(1),
  },
}));

function MainContent() {
  const { pictures, upload, trash, download } = usePictures();
  const [zoomed, setZoomed] = useState<Photo>();

  const classes = useStyles();
  // FIXME: Also support drag and drop... https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#selecting_files_using_drag_and_drop
  const onFileSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      Array.from(event.target.files || []).forEach((file) => {
        if (!file.type.startsWith("image/")) {
          /// FIXME: Do this in a cleaner way
          alert(`Oups, "${file.name}" was not an image.`);
        } else if (file.size > 50000000) {
          /// FIXME: Do this in a cleaner way
          alert(`Oups, "${file.name}" was too big.`);
        } else {
          const reader = new FileReader();
          reader.onload = (event) => event.target?.result && upload(file, event.target.result);
          reader.readAsArrayBuffer(file);
        }
      });
    },
    [upload]
  );

  const [deleting, setDeleting] = useState<Photo>();

  return (
    <div>
      <div className={classes.imageGrid}>
        {pictures.map((picture) => (
          <Paper key={picture.id} className={classes.imageTile}>
            <img src={picture.resized_url} alt="" className={classes.image} />
            <IconButton onClick={() => download(picture)} size="small" className={classes.download}>
              <CloudDownload />
            </IconButton>
            <IconButton onClick={() => setDeleting(picture)} size="small" className={classes.delete}>
              <Delete />
            </IconButton>
            <IconButton onClick={() => setZoomed(picture)} size="small" className={classes.zoom}>
              <ZoomIn />
            </IconButton>
          </Paper>
        ))}
      </div>
      <Backdrop className={classes.backdrop} open={!!zoomed} onClick={() => setZoomed(undefined)}>
        <img src={zoomed?.original_url} alt="" className={classes.zoomed} />
      </Backdrop>
      <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={!!deleting}>
        <DialogTitle id="confirmation-dialog-title">Supprimer l'image?</DialogTitle>
        <DialogContent dividers>
          Ceci ne peut pas être défait par l'interface web. Êtes vous certain de vouloir continuer avec cette action?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleting(undefined)} color="primary">
            Non
          </Button>
          <Button
            onClick={() => {
              if (deleting) {
                trash(deleting);
                setDeleting(undefined);
              }
            }}
            color="secondary"
          >
            Oui, supprimer cette image.
          </Button>
        </DialogActions>
      </Dialog>
      <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={onFileSelected}
        />

        <Fab
          color="primary"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          classes={{ root: classes.fab, label: classes.fabLabel }}
        >
          <AddAPhoto className={classes.icon} />
          <p>Ajouter une photo</p>
        </Fab>
      </label>
    </div>
  );
}

function Loading() {
  return (
    <div>
      <p>En chargement, veillier s.v.p. patienter.</p>
    </div>
  );
}

function assertNever(option: never) {}

const useAppStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

var id_count = 0;

function App() {
  const { user, isAuthenticated, isLoading, error } = useAuth0();
  const logged_in_roles = user && getRoles(user);
  const approved = logged_in_roles?.includes("Approved") ?? false;
  // TODO: Use react router instead of manually faking it like this.
  const [route, setRoute] = useState<"main" | "users">("main");
  const [loadingState, setLoadingState] = useState<number[]>([]);
  const classes = useAppStyles();

  const loading = useCallback(() => {
    const ptr = ++id_count;
    setLoadingState((pending = []) => [...pending, ptr]);
    return ptr;
  }, [setLoadingState]);

  const done = useCallback(
    (id: number) => {
      setLoadingState((pending) => pending.filter((value) => value !== id));
    },
    [setLoadingState]
  );

  if (error) {
    console.error(error);
  }

  let view;
  if (isLoading) {
    view = <Loading />;
  } else if (isAuthenticated && approved) {
    switch (route) {
      case "main":
        view = <MainContent />;
        break;
      case "users":
        view = <ManageUsers onClose={() => setRoute("main")} />;
        break;
      default:
        assertNever(route);
    }
  } else if (isAuthenticated && !approved) {
    view = <SeekApproval />;
  } else {
    view = <PleaseLogIn />;
  }

  return (
    <LoadingContext.Provider value={{ loading, done }}>
      <Backdrop open={isLoading || loadingState.length !== 0} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <div className="App">
        <NavBar onManageUsers={() => setRoute("users")}></NavBar>
        {view}
      </div>
    </LoadingContext.Provider>
  );
}

export default App;

import { Dispatch, useCallback, useEffect, useRef } from "react";
import { WebSocketEndpoint } from "../Config";
function assertNever(option: never) {}
export interface UseWebsocket {
  authToken?: string;
  onMessage: (message: any) => void;
}

type Event = { type: "error" } | { type: "close" } | { type: "open" } | { type: "msg"; data: any };

function start_socket(authToken: string, dispatch: Dispatch<Event>): WebSocket {
  console.log("Connecting to websocket event server");
  const socket = new WebSocket(WebSocketEndpoint);

  socket.onopen = () => dispatch({ type: "open" });
  socket.onerror = () => dispatch({ type: "error" });
  socket.onclose = () => dispatch({ type: "close" });

  socket.onmessage = function (msg) {
    try {
      const json = JSON.parse(msg.data);
      dispatch({ type: "msg", data: json });
    } catch (e) {
      console.error("Server sent bad data", e);
      dispatch({ type: "error" });
    }
  };

  return socket;
}

export default function useWebsocket({ authToken, onMessage }: UseWebsocket) {
  const socket = useRef<WebSocket>();
  const timeout = useRef<NodeJS.Timeout>();
  const retryCount = useRef(0);

  const dispatch = useCallback(
    (event: Event) => {
      switch (event.type) {
        case "error":
          console.log("Network error detected, closing websocket.");
          socket.current?.close();
          break;
        case "open":
          retryCount.current = 0;
          break;
        case "close":
          timeout.current && clearTimeout(timeout.current);
          socket.current = undefined;

          // Delay by a minimum of 1ms and a maximum of 66s according to how many errors have been encountered
          const count = Math.min(retryCount.current, 8);
          const delay = Math.pow(4, count);

          console.log(`Websocket closed, scheduling reconnect in ${delay}ms`);
          timeout.current = setTimeout(() => {
            if (authToken && !socket.current) {
              socket.current = start_socket(authToken, dispatch);
            }
          }, delay);

          retryCount.current++;
          break;
        case "msg":
          onMessage(event.data);
          break;
        default:
          assertNever(event);
      }
    },
    [authToken, onMessage]
  );

  useEffect(() => {
    if (authToken) {
      socket.current = start_socket(authToken, dispatch);
    }

    function cleanup() {
      timeout.current && clearTimeout(timeout.current);

      if (socket.current) {
        socket.current.onclose = null;
        socket.current.close();
      }
      socket.current = undefined;
    }
    window.addEventListener("beforeunload", cleanup);
    return () => {
      cleanup();
      window.removeEventListener("beforeunload", cleanup);
    };
  }, [dispatch, authToken]);
}

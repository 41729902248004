import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface MenuAppBarProps {
  onManageUsers: () => void;
}

export default function MenuAppBar({ onManageUsers }: MenuAppBarProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getIdTokenClaims().then((value) => setRoles((value && value["https://mm2021.photo/roles"]) || []));
    } else {
      setRoles([]);
    }
  }, [getIdTokenClaims, setRoles, isAuthenticated, isLoading]);

  const ref = useRef<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => logout({ returnTo: window.location.origin });

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Album de mariage Mélanie et Matthieu
          </Typography>
          {!isLoading && isAuthenticated ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
                ref={ref}
              >
                <Avatar src={user?.picture} alt={user?.name} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {roles.includes("Admin") && <MenuItem onClick={onManageUsers}>Gérer les utilisateurs</MenuItem>}
                <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
              </Menu>
            </div>
          ) : (
            <Button
              aria-label="login to the application"
              color="inherit"
              onClick={loginWithRedirect}
              startIcon={<AccountCircle />}
            >
              S'identifier
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import { PictureAPIAudience } from "../Config";
import { useAuth0 } from "@auth0/auth0-react";
import { Photo } from "../types";
import { LoadingContext } from "../LoadingContext";
import useWebsocket from "./useWebsocket";

export interface UsePictures {
  pictures: Photo[];
  refresh: () => void;
  upload: (file: File, data: string | ArrayBuffer) => Promise<void>;
  trash: (id: Photo) => Promise<void>;
  download: (id: Photo) => void;
}

function useThrottle(fn: () => void, delay: number): () => void {
  return useMemo(() => {
    let timeout: NodeJS.Timeout | undefined;

    return function () {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = undefined;
          fn();
        }, delay);
      }
    };
  }, [fn, delay]);
}

export default function usePictures(): UsePictures {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  // TODO: Also use this in refresh
  const [authToken, setAuthToken] = useState<string | undefined>();
  useEffect(() => {
    let active = true;

    if (isAuthenticated) {
      getAccessTokenSilently({
        scope: "read:pictures",
        audience: PictureAPIAudience,
      }).then((accessToken) => {
        if (active) {
          setAuthToken(accessToken);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [isAuthenticated, getAccessTokenSilently, setAuthToken]);
  const [pictures, setPictures] = useState<Photo[]>([]);
  const { loading, done } = useContext(LoadingContext);

  const rawRefresh = useCallback(async () => {
    const loadingId = loading("refreshPictures");
    try {
      console.log("Fetching pictures");
      const accessToken = await getAccessTokenSilently({
        scope: "read:pictures",
        audience: PictureAPIAudience,
      });

      const response = await fetch("/data/pictures", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "get",
      });

      if (response.ok) {
        setPictures(await response.json());
      } else {
        console.log("Error fetching pictures;", await response.text());
      }
    } finally {
      done(loadingId);
    }
  }, [setPictures, getAccessTokenSilently, loading, done]);

  const refresh = useThrottle(rawRefresh, 200);

  const upload = useCallback<UsePictures["upload"]>(
    async (file, data) => {
      const loadingId = loading("upload");
      try {
        console.log("Upload requested for", file.name);
        const accessToken = await getAccessTokenSilently({
          scope: "write:pictures",
          audience: PictureAPIAudience,
        });

        const base64 = btoa(file.name);

        const response = await fetch(`/data/pictures?original_name=${base64}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": file.type,
          },
          method: "post",
          body: data,
        });

        if (response.ok) {
          await refresh();
        } else {
          console.log("Error uploading a picture;", await response.text());
        }
      } finally {
        done(loadingId);
      }
    },
    [refresh, getAccessTokenSilently, loading, done]
  );

  const trash = useCallback<UsePictures["trash"]>(
    async (picture) => {
      const loadingId = loading("trash");
      try {
        console.log("Delete requested for", picture.id);
        const accessToken = await getAccessTokenSilently({
          scope: "write:pictures",
          audience: PictureAPIAudience,
        });

        const response = await fetch(`/data/pictures/${picture.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "delete",
        });

        if (response.ok) {
          await refresh();
        } else {
          console.log("Error trashing a picture;", await response.text());
        }
      } finally {
        done(loadingId);
      }
    },
    [refresh, getAccessTokenSilently, loading, done]
  );

  const download = useCallback<UsePictures["download"]>((picture) => {
    // TODO: Make this open in the same window...
    window.open(picture.original_url);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useWebsocket({ authToken, onMessage: refresh });

  return { pictures, refresh, upload, trash, download };
}

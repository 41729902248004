import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { Domain, ClientId, PictureAPIAudience } from "./Config";

//
if (!Domain) {
  throw new Error("App misconfigured, missing domain.");
} else if (!ClientId) {
  throw new Error("App misconfigured, missing client id.");
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
        domain={Domain}
        clientId={ClientId}
        redirectUri={window.location.origin}
        audience={PictureAPIAudience}
        scope="read:pictures write:pictures read:users write:users"
        refresh={true}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

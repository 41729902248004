import logo from "../logo.svg";

export default function PleaseLogIn() {
  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <p>Vous devez vous authentifier pour utilise ce service.</p>
    </div>
  );
}

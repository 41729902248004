import { useState, useCallback, useEffect, useContext } from "react";
import { PictureAPIAudience } from "../Config";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../types";
import { LoadingContext } from "../LoadingContext";

export interface UseUsers {
  users: User[];
  refresh: () => void;
  activate: (user: User) => Promise<void>;
  deactivate: (user: User) => Promise<void>;
  promote: (user: User) => Promise<void>;
  demote: (user: User) => Promise<void>;
}

interface UserPatch {
  admin?: boolean;
  approved?: boolean;
}

export default function useUsers(): UseUsers {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<UseUsers["users"]>([]);
  const { loading, done } = useContext(LoadingContext);

  const refresh = useCallback(async () => {
    const loadingId = loading("refreshUsers");
    try {
      const accessToken = await getAccessTokenSilently({
        scope: "read:users",
        audience: PictureAPIAudience,
      });

      const response = await fetch("/data/users", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "get",
      });

      if (response.ok) {
        setUsers(await response.json());
      } else {
        console.log("Error fetching users;", await response.text());
      }
    } finally {
      done(loadingId);
    }
  }, [setUsers, getAccessTokenSilently, loading, done]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const patchUser = useCallback(
    async (user: User, patch: UserPatch) => {
      const loadingId = loading("patchUser");
      try {
        const accessToken = await getAccessTokenSilently({
          scope: "write:users",
          audience: PictureAPIAudience,
        });

        const response = await fetch(`/data/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(patch),
        });

        if (response.ok) {
          refresh();
        } else {
          console.log("Error fetching users;", await response.text());
        }
      } finally {
        done(loadingId);
      }
    },
    [refresh, getAccessTokenSilently, loading, done]
  );

  const promote = useCallback(
    async (user: User) => {
      console.log("Promoting user", user.id);
      await patchUser(user, { admin: true });
    },
    [patchUser]
  );

  const demote = useCallback(
    async (user: User) => {
      console.log("Demoting user", user.id);
      await patchUser(user, { admin: false });
    },
    [patchUser]
  );

  const activate = useCallback(
    async (user: User) => {
      console.log("Activating user", user.id);
      await patchUser(user, { approved: true });
    },
    [patchUser]
  );

  const deactivate = useCallback(
    async (user: User) => {
      console.log("Deactivating user", user.id);
      await patchUser(user, { approved: false });
    },
    [patchUser]
  );

  return {
    users,
    refresh,
    promote,
    demote,
    activate,
    deactivate,
  };
}

const { REACT_APP_CLIENT_ID, REACT_APP_DATA_AUDIENCE, REACT_APP_DOMAIN, REACT_APP_WEBSOCKET } = process.env;

export const Domain = REACT_APP_DOMAIN;
export const ClientId = REACT_APP_CLIENT_ID;

export const ManagementAudience = `https://${REACT_APP_DOMAIN}/api/v2/`;
export const PictureAPIAudience = REACT_APP_DATA_AUDIENCE;

if (!REACT_APP_WEBSOCKET) {
  throw new Error("REACT_APP_WEBSOCKET must be defined");
}
export const WebSocketEndpoint = REACT_APP_WEBSOCKET;
